<template>
  <footer>
      Все материалы и цены на сайте носят информационный характер и не являются публичной офертой (ст. 437 ГК РФ)
  </footer>
</template>

<script>
export default {

}
</script>

<style>

footer{
    background: #212426;
    text-align: center;
    padding: 10px;
    color: white;
}
</style>